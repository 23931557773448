<template>
    <div class="examinerManagement">
        <div class="flex-box">
            <div class="left">
            </div>
        </div>
        <el-table
            :data="classMonthCheckList"
            style="width: 100%">
            <el-table-column
                label="考核人"
                prop="manager_name"
                align="center"></el-table-column>
            <el-table-column
                label="状态"
                prop="statusStr"
                align="center"></el-table-column>
            <el-table-column
                label="操作"
                width="180px"
                align="center">
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    :disabled="scope.row.status === 1 ? true : false"
                    type="primary"
                    @click="lookScore(scope.row)">查看
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev,pager,next"
            background
            :page-count="page.pageCount"
            :page-size="page.currentPage"
            @current-change="classMonthCheck"
        ></el-pagination>

        <el-dialog
        title=""
        :visible.sync="evaluationManagementScoreDialogVisible"
        width="820px"
      >
        <div class="center form-height">
          <div class="title">竹香学校班级环境文化评比表</div>
          <table style="width:100%" border="1" cellspacing="0" cellpadding="3">
            <thead>
              <tr>
                <th >班级</th>
                <th>项目</th>
                <th>{{evaluationMonth}}</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(item) in evaluationManagementDetailList" >
                <tr v-for="(jtem,index2) in item.classList" :key="index2">
                  <td v-if="index2 == 0" rowspan="4">{{jtem.cname}}</td>
                  <td>{{jtem.name}}</td>
                  <td class="evaluationScoreStyle">
                    <el-input style="text-align:center;" disabled v-if="index2==0 || index2==2" v-model="jtem.score" placeholder="最多扣4分"></el-input>
                    <el-input disabled v-if="index2==1 || index2==3" v-model="jtem.score" placeholder="最多扣6分"></el-input>
                  </td>
                </tr>
              </template>

            </tbody>
          </table>
        </div>
        <!-- <span v-if="!evaluationManagementScoreDisabled" slot="footer" class="dialog-footer">
          <el-button @click="evaluationManagementScoreDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="evaluationManagementScoreConfirm">确 定</el-button>
        </span> -->
      </el-dialog>

    </div>
</template>

<script>
export default {
    name:'personalScoring',
    data(){
        return {
            page: { pageCount: 1, currentPage: 0 },
            // 月份id
            month_id:Number(this.$route.query.id),
            // 月份名称
            evaluationMonth:this.$route.query.evaluationMonth,
            // 学期id
            yearid:Number(this.$route.query.yearid),
            // 班级月份考核列表
            classMonthCheckList:[],
            // 对话框显示与隐藏
            evaluationManagementScoreDialogVisible:false,
            evaluationManagementDetailList:[]
            
        }
    },
    created(){
        // console.log(this.month_id)
        this.classMonthCheck()
        // console.log(this.evaluationMonth)
        
    },
    methods:{
        classMonthCheck(){
            this.$post(`/base/queryClassMonthCheckList.do`,{month_id:this.month_id})
            .then(res=>{
                console.log(res)
                this.classMonthCheckList = res.data
            })
        },
        // 查看分数
        lookScore(row){
            this.evaluationManagementScoreDialogVisible = true
            console.log(row)
            console.log(this.yearid)
            // manager_id
            this.$post(`/base/queryClassMonthScoreByCheck.do`,{
                month_id:this.month_id,
                yearid:this.yearid,
                teacherId:row.manager_id
            }).then(res=>{
                console.log(res.data)
                this.evaluationManagementData = res.data
                let arr = []
                res.data.forEach((item,index1)=>{
                let i = arr.findIndex((jtem)=>jtem.classid == item.classid)
                    if(i == -1){
                    let obj = {
                        classid:item.classid,
                        cname:item.cname,
                        classList:[]
                    }
                    obj.classList.push(item)
                    arr.push(obj)

                    }else{
                        arr[i].classList.push(item)
                    }  
                })
                // console.log(arr)
                this.evaluationManagementDetailList = arr
                console.log(this.evaluationManagementDetailList)


            })

        },
    }
}
</script>

<style scoped>
.form-height{
  height: 600px;
  overflow-x:auto;
}

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

/* .center {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.center .title{
  text-align: center;
  font-size:20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.center td{
  padding:8px 0px;
  padding-left: 6px;
  text-align: center;
}

.center td /deep/ .el-input{
  width: 85px;
  margin: 0 6px;
}

.center .evaluationScoreStyle{
  padding: 2px 0px;
}

.evaluationScoreStyle .el-input /deep/ .el-input__inner{
  padding: 3px 5px;
}

.picker {
  margin-left: 10px;
}

.display {
    display: inline-block;
}

.marginRight {
    margin-right: 10px;
}
</style>
